import React, { useEffect, useState } from "react";
import {
  Grid,
  GridColumn,
  getSelectedState,
  getSelectedStateFromKeyDown,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import styled from "styled-components";
import Button from "../../components/button/Button";
import DefaultLayout from "../../../src/components/layout/DefaultLayout";
import Flex from "../../components/layout/Flex";
import { hideLoading, showLoading } from "../../store/loadingReducer";
import { showAlert } from "../../store/alertReducer";
import * as APIS from "../../libs/apis";
import { useDispatch } from "react-redux";
import {
  numberWithCommas,
  objToQueryStr,
  numFormat,
  formatTime,
  formatPhone,
} from "../../libs/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { RadioGroup, Checkbox } from "@progress/kendo-react-inputs";
import esMessages from "../../libs/es.json";
import { getter } from "@progress/kendo-react-common";
import {
  IntlProvider,
  loadMessages,
  LocalizationProvider,
} from "@progress/kendo-react-intl";
import { createIndex } from "../../libs/utils";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

const initialDataState = {
  skip: 0,
  take: 10,
};

const Input = styled.input`
  width: ${(props) => (props.width ? props.width : "250px")};
  height: 35px;
  border-radius: 0.2rem;
  border: 1px solid #ededed;
  padding-left: 10px;
  &:focus {
    outline: 1px solid #4583ee;
    box-shadow: 0px 0px 2px red;
  }
`;

const FiltersLabel = styled.label`
  margin-left: 10px;
  width: 100px;
  align-self: center;
  font-size: 16px;
  font-family: "NotoSansKR-Bold";
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1112px;
  height: 100%;

  .k-grid .k-grid-container {
    height: 100px;
  }
`;

const DATA_ITEM_KEY = "id";
const idGetter = getter(DATA_ITEM_KEY);
loadMessages(esMessages, "es-KR");

const TradingAsset = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state?.buySell || {};
  const navigate = useNavigate();
  const [tab, setTab] = useState("signed");
  const page = initialDataState;
  const [currentGoldPrice, setCurrentGoldPrice] = useState("");
  const [currentSilverPrice, setCurrentSilverPrice] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [tradeList, setTradeList] = useState([]);
  const [tradeListTotal, setTradeListTotal] = useState({ grams: 0, amount: 0 });
  const [selectedState, setSelectedState] = useState({});
  const typeCheckedList = location.state?.buySell?.tradeType || [];
  const checkTradeTypeData = [
    { label: "매수", value: "BUY" },
    { label: "매도", value: "SELL" },
  ];
  const statusRadioData = [
    {
      label: "체결",
      value: "signed",
    },
    {
      label: "미체결",
      value: "notSigned",
    },
  ];

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
  };

  const onKeyDown = (event) => {
    const newSelectedState = getSelectedStateFromKeyDown({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
  };

  const handleValueChange = (key, value, more) => {
    navigate(location.pathname, {
      replace: true,
      state: {
        buySell: {
          ...location.state?.buySell,
          [key]: value || "",
          ...more,
        },
      },
    });
  };

  const pageChange = (event) => {
    navigate(location.pathname, {
      replace: true,
      state: {
        buySell: {
          ...location.state?.buySell,
          page: event.page.skip / event.page.take,
          size: event.page.take,
        },
      },
    });
  };
  // const toggleDialog = () => {
  //   setComponents(<>디테일 뷰입니다</>);
  // };

  const fetchList = () => {
    const param = {
      ...state,
      page: state.page,
      size: state.size,
    };
    delete param.status;

    const paramQuery = objToQueryStr(param);
    dispatch(showLoading());
    let fetchApis = [];
    if (state?.status === "signed") {
      setTab("signed");
      fetchApis = [
        APIS.getSignedTradeList(paramQuery).then(
          ({
            data: {
              success,
              message,
              data: { content, totalElements },
            },
          }) => {
            if (success) {
              setTradeList(
                content.map((item, index) => {
                  const {
                    completedAt,
                    tradeType,
                    pricePerGram,
                    pureTradeKrw,
                    userFee,
                    tradeGram,
                    userPhoneNumber,
                    recommendShopName,
                    inOutShopName,
                    usingShopName,
                  } = item;
                  return {
                    ...item,
                    completedAt: formatTime(completedAt, "YYYY-MM-DD HH:mm"),
                    tradeType: tradeType === "SELL" ? "매도" : "매수",
                    pricePerGram: numberWithCommas(pricePerGram),
                    pureTradeKrw: numberWithCommas(pureTradeKrw),
                    userFee: numberWithCommas(userFee),
                    tradeGram: numberWithCommas(tradeGram),
                    userPhoneNumber: formatPhone(userPhoneNumber),
                    recommendShopName: recommendShopName || "-",
                    inOutShopName: inOutShopName || "-",
                    usingShopName: usingShopName || "-",
                  };
                })
              );
              setTotalCount(totalElements);
            } else {
              dispatch(showAlert({ message: message }));
            }
          }
        ),
        APIS.getSignedTradeSummaryList(paramQuery).then(
          ({
            data: {
              success,
              message,
              data: { totalAmount, totalKrw },
            },
          }) => {
            if (success) {
              setTradeListTotal({
                grams: totalAmount > 0 ? numFormat(totalAmount) : 0,
                amount: totalKrw > 0 ? numFormat(totalKrw) : 0,
              });
            } else {
              dispatch(showAlert({ message: message }));
            }
          }
        ),
      ];
    } else {
      setTab("notSigned");
      fetchApis = [
        APIS.getNotSignedTradeList(paramQuery).then(
          ({
            data: {
              success,
              message,
              data: { content, totalElements },
            },
          }) => {
            if (success) {
              setTradeList(
                content.map((item, index) => {
                  const {
                    orderPrice,
                    created_at,
                    tradeType,
                    requestGram,
                    remainGram,
                    userPhoneNumber,
                    recommendShopName,
                    inOutShopName,
                    usingShopName,
                  } = item;
                  return {
                    ...item,
                    orderPrice: numberWithCommas(orderPrice),
                    created_at: formatTime(created_at, "YYYY-MM-DD HH:mm"),
                    tradeType: tradeType === "SELL" ? "매도" : "매수",
                    requestGram: numberWithCommas(requestGram),
                    remainGram: numberWithCommas(remainGram),
                    userPhoneNumber: formatPhone(userPhoneNumber),
                    recommendShopName: recommendShopName || "-",
                    inOutShopName: inOutShopName || "-",
                    usingShopName: usingShopName || "-",
                  };
                })
              );
              setTotalCount(totalElements);
            } else {
              dispatch(showAlert({ message: message }));
            }
          }
        ),
        APIS.getNotSignedTradeSummaryList(paramQuery).then(
          ({
            data: {
              success,
              message,
              data: { totalAmount, totalKrw },
            },
          }) => {
            if (success) {
              setTradeListTotal({
                grams: totalAmount > 0 ? numFormat(totalAmount) : 0,
                amount: totalKrw > 0 ? numFormat(totalKrw) : 0,
              });
            } else {
              dispatch(showAlert({ message: message }));
            }
          }
        ),
      ];
    }
    Promise.all(fetchApis).finally(() => dispatch(hideLoading()));
  };

  const dateSetfunc = (type) => {
    const today = new Date();
    if (type === "today") {
      navigate(location.pathname, {
        replace: true,
        state: {
          buySell: {
            ...location.state?.buySell,
            beginDate: formatTime(
              new Date(today.getFullYear(), today.getMonth(), today.getDate()),
              "YYYY-MM-DD"
            ),
            endDate: formatTime(new Date(), "YYYY-MM-DD"),
          },
        },
      });
    } else if (type === "week") {
      navigate(location.pathname, {
        replace: true,
        state: {
          buySell: {
            ...location.state.buySell,
            beginDate: formatTime(
              new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() - 7
              ),
              "YYYY-MM-DD"
            ),
            endDate: formatTime(new Date(), "YYYY-MM-DD"),
          },
        },
      });
    } else if (type === "month") {
      navigate(location.pathname, {
        replace: true,
        state: {
          buySell: {
            ...location.state?.buySell,
            beginDate: formatTime(
              new Date(
                today.getFullYear(),
                today.getMonth() - 1,
                today.getDate()
              ),
              "YYYY-MM-DD"
            ),
            endDate: formatTime(new Date(), "YYYY-MM-DD"),
          },
        },
      });
    } else if (type === "3month") {
      navigate(location.pathname, {
        replace: true,
        state: {
          buySell: {
            ...location.state?.buySell,
            beginDate: formatTime(
              new Date(
                today.getFullYear(),
                today.getMonth() - 3,
                today.getDate()
              ),
              "YYYY-MM-DD"
            ),
            endDate: formatTime(new Date(), "YYYY-MM-DD"),
          },
        },
      });
    }
  };

  const initState = () => {
    navigate(location.pathname, {
      replace: true,
      state: {
        ...location.state,
        buySell: {
          page: page.skip,
          size: page.take,
          beginDate: formatTime(new Date(), "YYYY-MM-DD"),
          endDate: formatTime(new Date(), "YYYY-MM-DD"),
          userCondition: "",
          tradeType: ["BUY", "SELL"],
          assetType: "GOLD",
          status: "signed",
        },
      },
    });
  };
  const cellRender = (cell, props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    if (
      props.field === "no" ||
      props.field === "created_at" ||
      props.field === "completedAt" ||
      props.field === "tradeType" ||
      props.field === "userName" ||
      props.field === "userPhoneNumber" ||
      props.field === "userId" ||
      props.field === "recommendShopName" ||
      props.field === "inOutShopName" ||
      props.field === "usingShopName"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "-webkit-center" }}>
          {props.dataItem[props.field]}
        </td>
      );
    } else if (
      props.field === "pricePerGram" ||
      props.field === "orderPrice" ||
      props.field === "pureTradeKrw" ||
      props.field === "requestGram" ||
      props.field === "userFee" ||
      props.field === "tradeGram" ||
      props.field === "remainGram"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}>
          <div style={{ float: "right" }}>{props.dataItem[props.field]}</div>
        </td>
      );
    }
    return cell;
  };

  useEffect(() => {
    if (Object.keys(state).length === 0) {
      navigate(location.pathname, {
        replace: true,
        state: {
          ...location.state,
          buySell: {
            status: "signed",
            page: page.skip,
            size: page.take,
            beginDate: formatTime(new Date(), "YYYY-MM-DD"),
            endDate: formatTime(new Date(), "YYYY-MM-DD"),
            tradeType: ["BUY", "SELL"],
            assetType: "GOLD",
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    // 접수현황 summary 조회
    dispatch(showLoading());
    const goldPrice = APIS.getcurrentmarketprice("GOLD")
      .then(({ data: { data, success, message } }) => {
        if (success) {
          setCurrentGoldPrice(data);
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((err) =>
        dispatch(showAlert({ message: err.response.data.message }))
      );

    const silverPrice = APIS.getcurrentmarketprice("SILVER")
      .then(({ data: { data, success, message } }) => {
        if (success) {
          setCurrentSilverPrice(data);
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((err) =>
        dispatch(showAlert({ message: err.response.data.message }))
      );

    Promise.all([goldPrice, silverPrice]).finally(() => {
      dispatch(hideLoading());
    });
  }, []);

  useEffect(() => {
    fetchList();
  }, [state.page, state.size]);

  return (
    <DefaultLayout>
      <Wrap>
        <div>
          <Grid
            style={{
              width: "100%",
            }}
            scrollable={"none"}
            data={[
              {
                title: "현재가격",
                gold: numberWithCommas(currentGoldPrice) + " KRW",
                silver: numberWithCommas(currentSilverPrice) + " KRW",
              },
            ]}>
            <GridColumn field="title" title="구분" width="40px" />
            <GridColumn
              field="gold"
              title="금"
              cell={(props) => {
                const { dataItem } = props;
                return (
                  <td>
                    <tr style={{ float: "right" }}>
                      <td>{dataItem.gold}</td>
                    </tr>
                  </td>
                );
              }}
            />
            <GridColumn
              field="silver"
              title="은"
              cell={(props) => {
                const { dataItem } = props;
                return (
                  <td>
                    <tr style={{ float: "right" }}>
                      <td>{dataItem.silver}</td>
                    </tr>
                  </td>
                );
              }}
            />
          </Grid>
        </div>
        <div
          style={{
            marginTop: 10,
            width: "100%",
          }}>
          <PanelBar
            style={{
              fontFamily: "NotoSansKR-Bold",
              border: "1px solid #9e9e9e",
            }}>
            <PanelBarItem expanded={true} title={"검색 조건"}>
              <div style={{ padding: "0.5%" }}>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: 10,
                  }}>
                  <Flex style={{ width: "50%" }}>
                    <FiltersLabel>현황상태</FiltersLabel>
                    <RadioGroup
                      style={{ fontSize: 16, fontFamily: "NotoSansKR-Bold" }}
                      data={statusRadioData}
                      layout={"horizontal"}
                      value={state?.status}
                      onChange={(e) => {
                        handleValueChange("status", e.value);
                      }}
                    />
                  </Flex>
                  <Flex style={{ width: "50%" }}>
                    <FiltersLabel>자산타입</FiltersLabel>
                    <RadioGroup
                      data={[
                        {
                          label: "금",
                          value: "GOLD",
                        },
                        {
                          label: "은",
                          value: "SILVER",
                        },
                      ]}
                      style={{ fontSize: 16, fontFamily: "NotoSansKR-Bold" }}
                      layout={"horizontal"}
                      value={state?.assetType}
                      onChange={(e) => {
                        handleValueChange("assetType", e.value);
                      }}
                    />
                  </Flex>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: 10,
                    alignItems: "center",
                  }}>
                  <Flex style={{ width: "50%" }}>
                    <FiltersLabel>거래</FiltersLabel>
                    {checkTradeTypeData.map((item, index) => {
                      const { value: checkValue, label } = item;
                      return (
                        <div
                          style={{
                            marginRight: 17,
                            fontSize: 16,
                            fontFamily: "NotoSansKR-Bold",
                            alignSelf: "center",
                          }}>
                          <Checkbox
                            key={index.toString()}
                            checked={typeCheckedList.includes(checkValue)}
                            onChange={(e) => {
                              if (e.target.value) {
                                handleValueChange("tradeType", [
                                  ...typeCheckedList,
                                  checkValue,
                                ]);
                              } else {
                                handleValueChange(
                                  "tradeType",
                                  typeCheckedList.filter(
                                    (e) => e !== checkValue
                                  )
                                );
                              }
                            }}
                            label={label}
                          />
                        </div>
                      );
                    })}
                    {/* <Checkbox
                      value={state?.tradeType === "BUY"}
                      onChange={() => {
                        handleValueChange("tradeType", "BUY");
                      }}
                      label={"매수"}
                    />
                    <Checkbox
                      value={state?.tradeType === "SELL"}
                      onChange={() => {
                        handleValueChange("tradeType", "SELL");
                      }}
                      label={"매도"}
                    /> */}
                  </Flex>
                  <Flex style={{ width: "50%" }}>
                    <FiltersLabel>기간</FiltersLabel>
                    <Flex
                      style={{ fontSize: 16, fontFamily: "NotoSansKR-Bold" }}>
                      <input
                        type="date"
                        value={state?.beginDate ? state.beginDate : ""}
                        onChange={(e) => {
                          handleValueChange("beginDate", e.target.value);
                        }}
                      />
                      ~
                      <input
                        type="date"
                        value={state?.endDate ? state?.endDate : ""}
                        onChange={(e) => {
                          handleValueChange("endDate", e.target.value);
                        }}
                      />
                      <Button
                        style={{
                          marginLeft: 5,
                        }}
                        onClick={() => dateSetfunc("today")}>
                        당일
                      </Button>
                      <Button
                        style={{
                          marginLeft: 5,
                        }}
                        onClick={() => dateSetfunc("week")}>
                        1주
                      </Button>
                      <Button
                        style={{
                          marginLeft: 5,
                        }}
                        onClick={() => dateSetfunc("month")}>
                        1개월
                      </Button>
                      <Button
                        style={{ marginLeft: 5 }}
                        onClick={() => dateSetfunc("3month")}>
                        3개월
                      </Button>
                    </Flex>
                  </Flex>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: 10,
                  }}>
                  <Flex
                    style={{
                      display: "flex",
                      width: "50%",
                    }}>
                    <FiltersLabel>회원 정보</FiltersLabel>
                    <Input
                      autoFocus={true}
                      placeholder="고객명, 휴대폰번호, 고객번호"
                      value={state?.userCondition ? state?.userCondition : ""}
                      onChange={(e) => {
                        handleValueChange("userCondition", e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          fetchList();
                        }
                      }}
                    />
                  </Flex>
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: 10,
                  }}>
                  <Button
                    className="buttons-container-button"
                    icon="search"
                    style={{ marginRight: 5 }}
                    onClick={fetchList}>
                    검색
                  </Button>
                  <Button
                    className="buttons-container-button"
                    icon="refresh"
                    onClick={initState}>
                    초기화
                  </Button>
                </div>
              </div>
            </PanelBarItem>
          </PanelBar>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: 16,
            fontFamily: "NotoSansKR-Bold",
            alignItems: "center",
          }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                marginRight: 10,
                fontWeight: "bold",
                fontSize: 16,
                fontFamily: "NotoSansKR-Bold",
              }}>
              {tab === "signed"
                ? `기간내 체결 중량 : ${tradeListTotal.grams} g`
                : `기간 내 미체결 중량 : ${tradeListTotal.grams} g`}
            </div>
            <div
              style={{
                display: "flex",
                fontWeight: "bold",
                fontSize: 16,
                fontFamily: "NotoSansKR-Bold",
              }}>
              주문대금 : {tradeListTotal.amount} KRW
            </div>
          </div>
        </div>
        {/* <div
          style={{
            width: "100%",
            height: "100%",
          }}
        > */}
        <LocalizationProvider language="es-KR" style={{ height: "100%" }}>
          <IntlProvider locale="es" style={{ height: "100%" }}>
            <Grid
              style={{
                // width: "100%",
                marginTop: 5,
                cursor: "pointer",
                fontSize: "16px",
                height: "100%",
                maxHeight: "1050px",
              }}
              cellRender={cellRender}
              data={
                tradeList?.length !== 0
                  ? tradeList?.map((item, index) => ({
                      ...item,
                      // no:
                      //   totalCount -
                      //   parseInt(state.page) * parseInt(state.size) -
                      //   index,
                      no: createIndex(state, index),
                      selected: selectedState[idGetter(item)],
                    }))
                  : []
              }
              skip={state.page * state.size}
              take={state.size}
              total={totalCount}
              resizable={true}
              pageable={{
                pageSizes: [10, 30, 50, 100],
                previousNext: true,
                info: true,
              }}
              selectable={{
                enabled: true,
              }}
              selectedField="selected"
              onSelectionChange={onSelectionChange}
              onKeyDown={onKeyDown}
              scrollable="scrollable"
              navigatable={true}
              dataItemKey={DATA_ITEM_KEY}
              onPageChange={pageChange}>
              <GridColumn field="no" title="번호" width={70} />
              {tab === "signed" ? (
                <GridColumn field="completedAt" title="거래시간" width={180} />
              ) : (
                <GridColumn field="created_at" title="등록시간" width={180} />
              )}
              <GridColumn field="tradeType" title="구분" width={80} />
              {tab === "signed" ? (
                <GridColumn field="pricePerGram" title="체결가격" width={170} />
              ) : (
                <GridColumn field="orderPrice" title="주문가격" width={170} />
              )}
              {tab === "signed" ? (
                <GridColumn field="pureTradeKrw" title="거래금액" width={170} />
              ) : (
                <GridColumn field="requestGram" title="주문수량" width={170} />
              )}
              {tab === "signed" ? (
                <GridColumn field="userFee" title="수수료" width={170} />
              ) : (
                <></>
              )}
              {tab === "signed" ? (
                <GridColumn field="tradeGram" title="체결량" width={170} />
              ) : (
                <GridColumn field="remainGram" title="미체결량" width={170} />
              )}
              <GridColumn field="userName" title="주문자" width={100} />
              <GridColumn field="userId" title="고객번호" width={90} />
              <GridColumn field="userPhoneNumber" title="휴대폰" width={110} />
              <GridColumn
                field="recommendShopName"
                title="추천대리점"
                width={140}
              />
              <GridColumn
                field="inOutShopName"
                title="감정대리점"
                width={140}
              />
              <GridColumn
                field="usingShopName"
                title="이용대리점"
                width={140}
              />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
        {/* </div> */}
      </Wrap>
    </DefaultLayout>
  );
};

export default TradingAsset;
